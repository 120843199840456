/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as SDK from '@replai-platform/sdk';
import xor from 'lodash/xor';
import { batch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { logEvent } from '../../analytics';
import { toFormattedDate } from '../../components/FilterBar/DatesPicker/helpers';
import { ActivityType, Page } from '../../utils/enums';
import { isoDateSubtract } from '../../utils/isoDateSubtract';

const todayDate = new Date();
export const today = todayDate.toISOString().substring(0, 10);

const yesterdayDate = new Date(todayDate);
yesterdayDate.setDate(todayDate.getDate() - 1);
export const yesterday = yesterdayDate.toISOString().substring(0, 10);

export const DEFAULT_DATE_RANGE_DAYS = 8;
export const defaultStartDate = isoDateSubtract(yesterday, DEFAULT_DATE_RANGE_DAYS);
export const defaultEndDate = yesterday;

/**
 * Retrieves the date from 'dateRangeDays' before yesterday.
 *
 * @param dateRangeDays total of days
 * @returns date
 */
export const dateFromDateRangeDaysBeforeYesterday = (dateRangeDays: number | undefined) =>
  isoDateSubtract(yesterday, (dateRangeDays || DEFAULT_DATE_RANGE_DAYS) - 1);

export type Tag = {
  type: string;
  value: string | null;
  kind?: string;
};

type StringStatePayload = {
  value: string[];
  logEvent: boolean;
  eventPrefix?: string;
};

type TagStatePayload = {
  value: Tag[];
  toExclude: boolean;
  logEvent: boolean;
  eventPrefix?: string;
};

type MarketNetworkStatePayload = {
  value: SDK.Network[];
  logEvent: boolean;
  page?: Page;
  eventPrefix?: string;
};

type ActivityTypeStatePayload = {
  value: ActivityType;
  logEvent: boolean;
  eventPrefix?: string;
};

type DateRangeStatePayload = {
  startDate?: string;
  endDate?: string;
  logEvent: boolean;
  page?: Page;
  value: string | number;
};

export type FiltersState = {
  adSetsIds: string[];
  assetTypes: string[];
  campaignIdsToConsider: string[];
  campaignIdsToExclude: string[];
  countries: string[];
  endDate: string;
  kpi: string | null;
  kpis: SDK.MetricKPIWithSpend[];
  selectedInsightIds: string | string[] | null; // TODO This should be moved to combinations page
  promotedObjectTypesToConsider: SDK.PromotedObjectType[];
  minSpend: number;
  minInstalls: number;
  minImpressions: number;
  activity: ActivityType;
  networks: SDK.Network[];
  networkAccountIds: string[];
  startDate: string;
  tagFilters: SDK.Tag[];
  tagsToConsider: Tag[];
  tagsToExclude: Tag[];
  tagTypesToConsider: string[];
  tagValuesFilter: string;
  tagValuesSorting: string;
  numExcludedTopCreatives: number;
  rangeDays: string | number;
  ageStartDate: string | null;
  ageEndDate: string | null;
  ageRangeDays: string | number;
  shouldRefreshConceptsCache: boolean;
  maxFirstAppearanceSeconds: number | null;
  minFirstAppearanceSeconds: number | null;

  // Poorly implemented filter due to customer specificity: https://replai.atlassian.net/browse/TECHMERC-1525
  buyingStrategyCampaignsToConsider: string[];

  // Poorly implemented filter due to customer specificity: https://replai.atlassian.net/browse/TECHMERC-1550
  agencyCampaignsToConsider: string[];

  // market
  marketAppsToConsider: SDK.MarketApp[];
  marketAppsToExclude: SDK.MarketApp[];
  onlyMarketCompetitors: boolean;
  publishers: string[];
  marketTags: SDK.Tag[];
  marketNetworks: SDK.Network[];
};

export const initialState: FiltersState = {
  adSetsIds: [],
  assetTypes: [],
  campaignIdsToConsider: [],
  campaignIdsToExclude: [],
  countries: ['ALL'],
  selectedInsightIds: null,
  kpi: null,
  kpis: [],
  promotedObjectTypesToConsider: [],
  minSpend: 0,
  minInstalls: 0,
  minImpressions: 0,
  activity: ActivityType.all,
  networks: [],
  networkAccountIds: [],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  rangeDays: DEFAULT_DATE_RANGE_DAYS,
  tagFilters: [],
  tagsToConsider: [],
  tagsToExclude: [],
  tagTypesToConsider: [],
  tagValuesFilter: 'all',
  tagValuesSorting: 'higher_spenders',
  numExcludedTopCreatives: 0,
  ageStartDate: null,
  ageEndDate: null,
  ageRangeDays: 0,
  shouldRefreshConceptsCache: false,
  maxFirstAppearanceSeconds: null,
  minFirstAppearanceSeconds: null,
  buyingStrategyCampaignsToConsider: [],
  agencyCampaignsToConsider: [],

  // market
  marketAppsToConsider: [],
  marketAppsToExclude: [],
  onlyMarketCompetitors: true,
  publishers: [],
  marketTags: [],
  marketNetworks: [],
};

const FiltersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    changeMinSpend: (
      state,
      action: { payload: { value: number | string; logEvent: boolean; removeMinSpend?: boolean } }
    ) => {
      const newSpend = Number.parseInt(action.payload.value as string, 10);

      if (action.payload.logEvent && !action.payload.removeMinSpend && newSpend !== state.minSpend) {
        logEvent({
          component: 'Sidebar Filters',
          action: 'Change Min Spend',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }

      state.minSpend = newSpend;
    },
    changeMinInstalls: (
      state,
      action: { payload: { value: number | string; logEvent: boolean; removeMinInstalls?: boolean } }
    ) => {
      const newInstalls = Number.parseInt(action.payload.value as string, 10);

      if (action.payload.logEvent && !action.payload.removeMinInstalls && newInstalls !== state.minInstalls) {
        logEvent({
          component: 'Sidebar Filters',
          action: 'Change Min Installs',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }

      state.minInstalls = newInstalls;
    },
    changeMinImpressions: (
      state,
      action: { payload: { value: number | string; logEvent: boolean; removeMinImpressions?: boolean } }
    ) => {
      const newImpressions = Number.parseInt(action.payload.value as string, 10);

      if (action.payload.logEvent && !action.payload.removeMinImpressions && newImpressions !== state.minImpressions) {
        logEvent({
          component: 'Sidebar Filters',
          action: 'Change Min Impressions',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }

      state.minImpressions = newImpressions;
    },
    changeActivity: (state, action: PayloadAction<ActivityTypeStatePayload>) => {
      if (action.payload.logEvent && state.activity !== action.payload.value) {
        logEvent({
          component: 'Switcher',
          action: 'Toggle Active Videos',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.activity = action.payload.value;
    },
    changeAssetTypes: (state, action: PayloadAction<{ assetTypes?: string[] | null }>) => {
      state.assetTypes = action.payload.assetTypes ?? [];
    },
    // used for single select KPI filter
    changeKPI: (state, action: { payload: { value: string; logEvent: boolean; page?: Page } }) => {
      state.kpi = action.payload.value;
      if (action.payload.logEvent) {
        logEvent({
          component: 'Filters',
          action: 'Change KPI',
          category: 'user_actions',
          parameters: { value: action.payload.value, page: action.payload.page },
        });
      }
    },
    // used for multi select KPI filter
    changeKPIs: (state, action: { payload: { value: SDK.MetricKPIWithSpend[]; logEvent: boolean; page?: Page } }) => {
      state.kpis = action.payload.value;
      if (action.payload.logEvent) {
        logEvent({
          component: 'Filters',
          action: 'Change KPIs',
          category: 'user_actions',
          parameters: { value: action.payload.value, page: action.payload.page },
        });
      }
    },
    changeSelectedInsightIds: (state, action: { payload: string | string[] | null }) => {
      state.selectedInsightIds = action.payload;
    },
    changePromotedObjectTypesToConsider: (
      state,
      action: { payload: { logEvent: boolean; value: SDK.PromotedObjectType[]; page?: Page } }
    ) => {
      if (action.payload.logEvent && xor(state.promotedObjectTypesToConsider, action.payload.value).length > 0) {
        logEvent({
          component: 'Filters',
          action: 'Change OS',
          category: 'user_actions',
          parameters: { page: action.payload.page, value: action.payload.value },
        });
      }

      state.promotedObjectTypesToConsider = action.payload.value;
    },
    changeNetworks: (state, action: { payload: { value: SDK.Network[]; page?: Page; logEvent: boolean } }) => {
      if (action.payload.logEvent) {
        logEvent({
          component: 'Filters',
          action: 'Change Network',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.networks = action.payload.value;
    },
    changeNetworkAccountIds: (state, action: PayloadAction<StringStatePayload>) => {
      if (action.payload.logEvent && xor(state.networkAccountIds, action.payload.value).length > 0) {
        // This action is not used in the UI yet, so the logEvent is not tested
        logEvent({
          component: 'Sidebar Filters',
          action: 'Change Network Accounts',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.networkAccountIds = action.payload.value;
    },
    changeCampaigns: (
      state,
      action: PayloadAction<StringStatePayload & { toExclude: boolean; logComponent?: string }>
    ) => {
      if (
        action.payload.logEvent &&
        (action.payload.toExclude
          ? xor(state.campaignIdsToExclude, action.payload.value).length > 0
          : xor(state.campaignIdsToConsider, action.payload.value).length > 0)
      ) {
        logEvent({
          component: action.payload.logComponent ?? 'Sidebar Filters',
          action: 'Change Campaigns',
          category: 'user_actions',
          parameters: { ...action.payload, toExclude: action.payload.toExclude },
        });
      }
      batch(() => {
        state.campaignIdsToConsider = action.payload.toExclude ? [] : action.payload.value;
        state.campaignIdsToExclude = action.payload.toExclude ? action.payload.value : [];
        if (action.payload.logComponent === 'BuyingStrategyFilter') {
          state.buyingStrategyCampaignsToConsider = [];
        }

        if (action.payload.logComponent === 'AgencyFilter') {
          state.agencyCampaignsToConsider = [];
        }
      });
    },
    changeCampaignsForBuyStrategy: (state, action: PayloadAction<StringStatePayload & { logComponent?: string }>) => {
      if (action.payload.logEvent) {
        logEvent({
          component: action.payload.logComponent ?? 'Sidebar Filters',
          action: 'Change Buy Strategy',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.buyingStrategyCampaignsToConsider = action.payload.value;
    },
    changeCampaignsForAgency: (state, action: PayloadAction<StringStatePayload & { logComponent?: string }>) => {
      if (action.payload.logEvent) {
        logEvent({
          component: action.payload.logComponent ?? 'Sidebar Filters',
          action: 'Change Agency',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.agencyCampaignsToConsider = action.payload.value;
    },
    changeCountries: (state, action: PayloadAction<StringStatePayload>) => {
      const transformedPayloadValue =
        !action.payload.value || action.payload.value?.length === 0 ? ['ALL'] : action.payload.value;
      if (action.payload.logEvent && xor(state.countries, transformedPayloadValue).length > 0) {
        logEvent({
          component: 'Sidebar Filters',
          action: 'Change Countries',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.countries = transformedPayloadValue;
    },
    changeTagFilters: (state, action: { payload: SDK.Tag[] }) => {
      state.tagFilters = action.payload;
    },
    changeTags: (state, action: PayloadAction<TagStatePayload>) => {
      if (action.payload.logEvent && xor(state.tagsToConsider, action.payload.value).length > 0) {
        logEvent({
          component: 'Sidebar Filters',
          // Ideally the event name would be 'Change Tags' however, to not mess up existing events, keep it like this
          action: `Change Tags To Consider`,
          category: 'user_actions',
          parameters: { ...action.payload, toExclude: action.payload.toExclude },
        });
      }
      batch(() => {
        state.tagsToConsider = action.payload.toExclude ? [] : action.payload.value;
        state.tagsToExclude = action.payload.toExclude ? action.payload.value : [];
      });
    },
    changeDateRange: (
      state,
      action: { payload: { startDate: string; endDate: string; rangeDays: string | number; logEvent: boolean } }
    ) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.rangeDays = action.payload.rangeDays;

      if (action.payload.logEvent || action.payload.logEvent === undefined) {
        logEvent({
          component: 'Date picker',
          action: 'Change date range',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
    },
    changeAgeDateRange: (state, action: PayloadAction<DateRangeStatePayload>) => {
      const rangeDaysNum = Number(action.payload.value ?? 0);
      const currentDate = new Date();
      const startDate =
        action.payload.value !== 'custom' ? new Date(isoDateSubtract(currentDate, rangeDaysNum)) : new Date();

      const ageStartDate =
        rangeDaysNum === 0 || action.payload.value === 'custom'
          ? action.payload.startDate ?? null
          : toFormattedDate({
              year: startDate.getUTCFullYear(),
              month: startDate.getMonth() + 1,
              day: startDate.getUTCDate(),
            }) ?? null;
      const ageEndDate =
        rangeDaysNum === 0 || action.payload.value === 'custom'
          ? action.payload.endDate ?? null
          : toFormattedDate({
              year: currentDate.getUTCFullYear(),
              month: currentDate.getMonth() + 1,
              day: currentDate.getUTCDate(),
            }) ?? null;

      if (
        action.payload.logEvent &&
        (state.ageStartDate !== ageStartDate || state.ageEndDate !== ageEndDate || state.ageRangeDays !== rangeDaysNum)
      ) {
        logEvent({
          component: 'Filters',
          action: 'Change Launch Date',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }

      state.ageStartDate = ageStartDate;
      state.ageEndDate = ageEndDate;
      state.ageRangeDays = rangeDaysNum;
    },
    changeShouldRefreshConceptsCache: (state, action) => {
      state.shouldRefreshConceptsCache = action.payload;
    },
    changeMaxFirstAppearanceSeconds: (
      state,
      action: { payload: { value?: number; page?: Page; logEvent: boolean } }
    ) => {
      if (action.payload.logEvent) {
        logEvent({
          component: 'Filters',
          action: 'Toggle Intro Tags Only',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }

      state.maxFirstAppearanceSeconds = action.payload.value ?? null;
    },

    // market
    changeMarketAppsToConsider: (
      state,
      action: { payload: { value: SDK.MarketApp[]; page?: Page; logEvent: boolean } }
    ) => {
      if (action.payload.logEvent && xor(state.marketAppsToConsider, action.payload.value).length > 0) {
        logEvent({
          component: 'Filters',
          action: 'Change App to exclude',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.marketAppsToConsider = action.payload.value;
    },
    changeMarketAppsToExclude: (
      state,
      action: { payload: { value: SDK.MarketApp[]; page?: Page; logEvent: boolean } }
    ) => {
      if (action.payload.logEvent && xor(state.marketAppsToExclude, action.payload.value).length > 0) {
        logEvent({
          component: 'Filters',
          action: 'Change App to consider',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }
      state.marketAppsToExclude = action.payload.value;
    },
    changeOnlyMarketCompetitors: (state, action: { payload: { value: boolean } }) => {
      logEvent({
        component: 'Filters',
        action: 'Change Market Only Competitors',
        category: 'user_actions',
        parameters: { ...action.payload },
      });

      state.onlyMarketCompetitors = action.payload.value;
    },
    changePublishers: (state, action: { payload: { value: string[]; page?: Page; logEvent: boolean } }) => {
      if (action.payload.logEvent && xor(state.publishers, action.payload.value).length > 0) {
        logEvent({
          component: 'Filters',
          action: 'Change Publisher',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }

      state.publishers = action.payload.value;
    },
    changeMarketTags: (state, action: PayloadAction<{ value: FiltersState['marketTags']; logEvent: boolean }>) => {
      if (action.payload.logEvent && xor(state.marketTags, action.payload.value).length > 0) {
        // TODO: add analytics test
        logEvent({
          component: `Filters`,
          action: 'Change Tags to consider',
          category: 'user_actions',
          parameters: { value: action.payload.value, page: Page.MarketVideosLibrary },
        });
      }

      state.marketTags = action.payload.value;
    },
    changeMarketNetworks: (state, action: PayloadAction<MarketNetworkStatePayload>) => {
      if (action.payload.logEvent && xor(state.marketNetworks, action.payload.value).length > 0) {
        logEvent({
          component: 'Filters',
          action: 'Change Market Networks',
          category: 'user_actions',
          parameters: { ...action.payload },
        });
      }

      state.marketNetworks = action.payload.value;
    },
  },
});

export const FilterActions = FiltersSlice.actions;
export const FilterReducer = FiltersSlice.reducer;
export const FilterInitialState = initialState;
