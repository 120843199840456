/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as SDK from '@replai-platform/sdk';
import { api } from '../../api';
import { FiltersState } from '../../store/filters';
import { Filters } from '../../utils/enums';

export const supportedFilters = [
  Filters.Spend,
  Filters.Installs,
  Filters.Impressions,
  Filters.Campaigns,
  Filters.Countries,
];

export const PROPERTY_TAG_TYPES = [
  SDK.CoreTags.ASPECT_RATIO,
  SDK.CoreTags.COLORFULNESS,
  SDK.CoreTags.CUT_FREQUENCY,
  SDK.CoreTags.INTRO_LOUDNESS,
  SDK.CoreTags.LANGUAGE,
  SDK.CoreTags.LUMINOSITY,
  SDK.CoreTags.NUMBER_OF_SCENES,
  SDK.CoreTags.ORIENTATION,
  SDK.CoreTags.SATURATION,
  SDK.CoreTags.SCENE_TRANSITIONS_DIFF,
];

export async function getMetricsHistoryEntries({
  projectId,
  filters,
  metrics,
  conceptId,
  assetId,
}: {
  projectId: string;
  filters: FiltersState;
  metrics: SDK.MetricOrKpi[];
  conceptId?: SDK.UUID;
  assetId?: SDK.UUID;
}): Promise<
  {
    name: string;
    globalMetrics: Record<string, any>;
    assetMetrics: Record<string, any>;
    [key: string]: any;
  }[]
> {
  const [globalHistory, assetHistory] = await Promise.all([
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
    }),
    api.metrics.getHistoryMetrics({
      projectIds: [projectId],
      metrics,
      adsFilters: api.filterConverter.getAdsFilters(filters),
      assetFilters: api.filterConverter.getAssetFilters(filters),
      metricsFilters: api.filterConverter.getMetricsFilters(filters),
      conceptId,
      assetId,
    }),
  ]);

  return globalHistory.history.map((entry) => {
    const copyObjectExcludingDate = (object) => (({ date, ...o }) => o)(object);
    let assetMetrics = assetHistory.history.find((e) => e.date === entry.date);
    if (assetMetrics) {
      assetMetrics = copyObjectExcludingDate(assetMetrics);
    }

    const historyEntry = {
      name: entry.date,
      globalMetrics: entry?.metrics,
      assetMetrics: assetMetrics?.metrics ?? {},
    };
    metrics.forEach((metric) => {
      historyEntry[metric] = assetMetrics?.metrics[metric];
    });
    return historyEntry;
  });
}
