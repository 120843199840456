/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as SDK from '@replai-platform/sdk';
import { MultiSelectDropDownOption } from '@replai-platform/ui-components';
import { FILTER_SUFFIX } from '../../../utils/constants';
import { FilterActions, FiltersState } from '../../../store/filters';
import { Filters } from '../../../utils/enums';
import { fetchCampaigns } from './fetchCampaigns';
import { fetchCountries } from './fetchCountries';
import { fetchTags } from './fetchCustomTags';
import fetchMarketNetworks from './fetchMarketNetworks';

export function getFilterTitle(filter: Filters): string {
  switch (filter) {
    case Filters.Campaigns:
      return 'Filter by Campaign';
    case Filters.CustomTag:
      return 'Filter by Custom Tag';
    case Filters.Spend:
      return 'Spend minimum';
    case Filters.Installs:
      return 'Installs minimum';
    case Filters.Impressions:
      return 'Impressions minimum';
    case Filters.Countries:
      return 'Filter by Country';
    case Filters.LaunchedDate:
      return 'Filter by Launched Date';
    case Filters.OS:
      return 'Filter by OS';
    case Filters.MarketNetwork:
      return 'Filter by Network';
    default:
      return '';
  }
}

export function getFilterAction(filter: Filters) {
  switch (filter) {
    case Filters.Campaigns:
      return FilterActions.changeCampaigns;
    case Filters.CustomTag:
      return FilterActions.changeTags;
    case Filters.Countries:
      return FilterActions.changeCountries;
    case Filters.LaunchedDate:
      return FilterActions.changeAgeDateRange;
    case Filters.OS:
      return FilterActions.changePromotedObjectTypesToConsider;
    case Filters.MarketNetwork:
      return FilterActions.changeMarketNetworks;
    default:
      return null;
  }
}

export function getFilterShowCondition(filter: Filters, filtering: FiltersState): boolean {
  switch (filter) {
    case Filters.Campaigns:
      return filtering.campaignIdsToConsider?.length > 0 || filtering.campaignIdsToExclude?.length > 0;
    case Filters.Countries:
      return filtering.countries?.length > 0 && !filtering.countries.includes('ALL');
    case Filters.CustomTag:
      return filtering.tagsToConsider?.length > 0 || filtering.tagsToExclude?.length > 0;
    case Filters.Spend:
      return !!filtering.minSpend;
    case Filters.Installs:
      return !!filtering.minInstalls;
    case Filters.Impressions:
      return !!filtering.minImpressions;
    case Filters.OS:
      return filtering.promotedObjectTypesToConsider?.length > 0;
    case Filters.MarketNetwork:
      return filtering.marketNetworks?.length > 0;
    case Filters.LaunchedDate:
      return !!filtering.ageRangeDays && filtering.ageRangeDays !== 0 && filtering.ageRangeDays !== '0';
    default: {
      const exhaustiveCheck: never = filter;
      return exhaustiveCheck;
    }
  }
}

export async function getFilterOptions(
  filter: Filters,
  projectId: SDK.UUID,
  filtering: FiltersState
): Promise<MultiSelectDropDownOption[]> {
  switch (filter) {
    case Filters.Campaigns:
      return fetchCampaigns(projectId, filtering);
    case Filters.Countries:
      return fetchCountries(projectId, filtering);
    case Filters.MarketNetwork:
      return fetchMarketNetworks({ projectId, currentFiltering: filtering });
    case Filters.CustomTag:
      return fetchTags(projectId, filtering);
    case Filters.OS:
      return Promise.resolve([
        {
          id: 'ALL',
          label: 'All',
          isAllOption: true,
          selected: false,
          type: 'option',
        },
        ...SDK.PROMOTED_OBJECT_TYPES.map((promotedObjectType) => ({
          id: promotedObjectType,
          label: SDK.getPromotedObjectString(promotedObjectType),
          selected: filtering.promotedObjectTypesToConsider.includes(promotedObjectType),
        })),
      ]);
    case Filters.Spend:
      return Promise.resolve([] as MultiSelectDropDownOption[]);
    case Filters.Installs:
      return Promise.resolve([] as MultiSelectDropDownOption[]);
    case Filters.Impressions:
      return Promise.resolve([] as MultiSelectDropDownOption[]);
    case Filters.LaunchedDate:
      return Promise.resolve([
        {
          id: '0',
          label: 'All',
          isAllOption: false,
          type: 'option',
          selected: filtering.ageRangeDays === 0,
        } as MultiSelectDropDownOption,
        {
          id: '30',
          label: 'Last 30 days',
          isAllOption: false,
          type: 'option',
          selected: filtering.ageRangeDays === 30,
        } as MultiSelectDropDownOption,
        {
          id: '60',
          label: 'Last 60 days',
          isAllOption: false,
          type: 'option',
          selected: filtering.ageRangeDays === 60,
        } as MultiSelectDropDownOption,
        {
          id: '180',
          label: 'Last 180 days',
          isAllOption: false,
          type: 'option',
          selected: filtering.ageRangeDays === 180,
        } as MultiSelectDropDownOption,
      ]);
    default: {
      const exhaustiveCheck: never = filter;
      return exhaustiveCheck;
    }
  }
}

export function getFilterLabel(filter: Filters): string {
  switch (filter) {
    case Filters.Campaigns:
      return 'Campaigns';
    case Filters.CustomTag:
      return 'Custom Tag';
    case Filters.Countries:
      return 'Countries';
    case Filters.LaunchedDate:
      return 'Launch Date: ';
    case Filters.OS:
      return 'OS';
    case Filters.MarketNetwork:
    case Filters.Spend:
    case Filters.Installs:
    case Filters.Impressions:
      return '';
    default: {
      const exhaustiveCheck: never = filter;
      return exhaustiveCheck;
    }
  }
}

export function getFilterStateKey(filter: Filters, allFilterValues: FiltersState): string {
  switch (filter) {
    case Filters.Campaigns: {
      const toExclude = allFilterValues.campaignIdsToExclude.length > 0;
      return `campaignIds${toExclude ? FILTER_SUFFIX.TO_EXCLUDE : FILTER_SUFFIX.TO_CONSIDER}`;
    }
    case Filters.CustomTag: {
      const toExclude = allFilterValues.tagsToExclude.length > 0;
      return `tags${toExclude ? FILTER_SUFFIX.TO_EXCLUDE : FILTER_SUFFIX.TO_CONSIDER}`;
    }
    case Filters.Countries:
      return 'countries';
    case Filters.MarketNetwork:
      return 'marketNetworks';
    case Filters.LaunchedDate:
      return 'ageRangeDays';
    case Filters.OS:
      return 'promotedObjectTypesToConsider';
    default:
      return '';
  }
}

/**
 * Given a state filter, it returns if the corresponding option on the filter dropdown
 * should be selected or not
 *
 * @param filter filter name
 * @param filterValue filter value
 * @param item dropdown item
 * @param toExclude boolean that states if the filter is a 'toExclude' one, meaning
 * that the selected options are inverted
 * @returns
 */
export function getFilterSelectedCondition({
  filter,
  filterValue,
  item,
  toExclude,
}: {
  filter: Filters;
  filterValue: any;
  item: MultiSelectDropDownOption;
  toExclude: boolean;
}): boolean {
  switch (filter) {
    case Filters.CustomTag: {
      const selected = filterValue.some((value) => JSON.stringify(value) === item.id) as boolean;
      return toExclude ? !selected : selected;
    }
    case Filters.LaunchedDate:
      return Number(filterValue) === Number(item.id);
    default:
      return toExclude ? !filterValue.includes(item.id) : filterValue.includes(item.id);
  }
}
