import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

const getConceptTags = async (requestParams: SDK.GetConceptTagsRequest, conceptId: SDK.UUID) => {
  const res = await provider.getJson<object, SDK.GetConceptTagsResponse>(
    `${SDK.BASE_URL()}/concepts/${conceptId}/tags?${SDK.convertRequestToQueryParams({
      projectId: requestParams.projectId,
      ...(requestParams.assetId ? { assetId: requestParams.assetId } : {}),
    })}`
  );
  return res;
};

const KEY = 'concept-tags';
type Key = typeof KEY;

type Result = SDK.GetConceptTagsResponse;
type QueryKey = [Key, SDK.GetConceptTagsRequest, SDK.UUID];

type QueryOptions<R = Result> = UseQueryOptions<Result, unknown, R, QueryKey>;
type QueryResult<R = Result> = UseQueryResult<R>;

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams, conceptId] }) =>
  getConceptTags(requestParams, conceptId);

// This is exported so we can use useQueries
export function getConceptTagsQueryOptions<R = Result>(
  requestParams: SDK.GetConceptTagsRequest,
  conceptId: SDK.UUID,
  options?: QueryOptions<R>
): QueryOptions<R> {
  return {
    queryKey: [KEY, requestParams, conceptId],
    queryFn,
    ...options,
  };
}

export default function useConceptTags<R = Result>(
  requestParams: SDK.GetConceptTagsRequest,
  conceptId: SDK.UUID,
  options?: QueryOptions<R>
): QueryResult<R> {
  return useQuery(getConceptTagsQueryOptions(requestParams, conceptId, options));
}
