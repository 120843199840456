import { Icons, LoadingScreen, Notification } from '@replai-platform/ui-components';
import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useFeature } from '@optimizely/react-sdk';
import { api } from '../../api';
import { pageTransition } from '../../configurations/animations';
import { AppActions } from '../../store/app';
import { CombinationsActions } from '../../store/combinations';
import { FilterActions } from '../../store/filters';
import { InsightsActions } from '../../store/insights';
import { LibraryActions } from '../../store/library';
import { MarketActions } from '../../store/market';
import { ProjectActions } from '../../store/project';
import type { RootState } from '../../store/rootReducer';
import { TagsActions } from '../../store/tags';
import { TagViewActions } from '../../store/tagView';
import { CommonSnackbar } from '../../utils/styles';
import CompareSidebar from '../CompareSidebar';
import FilterSidebar from '../FilterSidebar';
import NavigationBar from '../NavigationBar';
import * as Styled from './styles';

function getDateStrings(days: number): { today: string; yesterday: string; pastDate: string } {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - days);

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return {
    today: formatDate(today),
    yesterday: formatDate(yesterday),
    pastDate: formatDate(pastDate),
  };
}

const LayoutContainer: React.FC = ({ children }) => {
  const project = useSelector((state: RootState) => state.project.id);
  const alert = useSelector((state: RootState) => state.app.alert);
  const match = useMatch(':projectId/*');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [isTechMerc1175Enabled] = useFeature('techmerc-1175'); // statics

  useEffect(() => {
    if (!match?.params.projectId) {
      navigate('/');
      return;
    }

    if ((!project && match?.params.projectId) || project !== match.params.projectId) {
      setIsLoadingProject(true);
      api.projects
        // TODO: implement project id filter
        .getProjects()
        .then((projects) => {
          setIsLoadingProject(true);
          const actions: any[] = [];

          /* eslint-disable */
          const { icon, uiPreferences, ...rest } = projects.find((p) => p.id === match.params.projectId);

          // setup minSpend filter
          if (rest.config?.minSpend) {
            actions.push(
              FilterActions.changeMinSpend({
                logEvent: false,
                value: rest.config.minSpend < 50 ? 50 : rest.config.minSpend,
              })
            );
          }

          // setup minInstalls filter
          if (rest.config?.minInstalls) {
            actions.push(
              FilterActions.changeMinInstalls({
                logEvent: false,
                value: rest.config.minInstalls,
              })
            );
          }

          // setup minImpressions filter
          if (rest.config?.minImpressions) {
            actions.push(
              FilterActions.changeMinImpressions({
                logEvent: false,
                value: rest.config.minImpressions,
              })
            );
          }

          actions.push(
            ProjectActions.changeProjectData({
              id: match.params.projectId,
              icon,
              userProject: { uiPreferences },
              ...rest,
            })
          );
          /* eslint-enable */

          if ((rest as { config?: { dateRangeDays?: number } })?.config?.dateRangeDays) {
            const { config } = rest as { config: { dateRangeDays: number } };
            const dates = getDateStrings(config.dateRangeDays);

            actions.push(
              FilterActions.changeDateRange({
                startDate: dates.pastDate,
                endDate: dates.yesterday,
                rangeDays: config.dateRangeDays,
                logEvent: false,
              })
            );
          }

          actions.push(FilterActions.changeAssetTypes({ assetTypes: [] }));

          batch(() => {
            dispatch(FilterActions.reset());
            dispatch(InsightsActions.reset());
            dispatch(TagViewActions.reset());
            dispatch(CombinationsActions.reset());
            dispatch(TagsActions.reset());
            dispatch(MarketActions.reset());
            dispatch(LibraryActions.reset());
            dispatch(AppActions.setCompareItems({}));
            actions.forEach((action) => {
              dispatch(action);
            });
          });

          setIsLoadingProject(false);
        })
        .catch(() => {
          // do nothing.
        });
    }
  }, [match?.params.projectId, dispatch, isTechMerc1175Enabled, navigate, project]);

  if (!project || !match?.params.projectId) {
    // Get Config
    return (
      <Styled.Loader>
        <h2>
          <Icons.BrandIcons.Replai dimension={48} />
        </h2>
        <h3>Loading Project</h3>
      </Styled.Loader>
    );
  }

  return (
    <Styled.Container initial="out" animate="in" exit="out" variants={pageTransition}>
      <Styled.LeftPanel data-hj-ignore-attributes>
        <NavigationBar />
      </Styled.LeftPanel>
      {isLoadingProject ? (
        <LoadingScreen />
      ) : (
        <Styled.RightPanel data-hj-ignore-attributes>
          {children}
          <CommonSnackbar
            key={Date.now()}
            open={alert?.open}
            onClose={() => dispatch(AppActions.setAlertClose())}
            anchorOrigin={alert?.anchorOrigin}
            autoHideDuration={alert?.duration}
          >
            <Notification
              badgeTitle={alert?.badgeTitle ?? ''}
              badgeLabel={alert?.badgeLabel ?? ''}
              message={alert?.message}
              color={alert?.color}
            />
          </CommonSnackbar>
        </Styled.RightPanel>
      )}
      <FilterSidebar />
      <CompareSidebar />
    </Styled.Container>
  );
};

export default LayoutContainer;
