import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import * as SDK from '@replai-platform/sdk';
import * as provider from '../../api/provider';

// TODO: Add to SDK
interface GetConceptPreviewInfoRequest {
  assetId?: SDK.UUID;
  projectId?: SDK.UUID;
  conceptId: SDK.UUID;
  size: string;
}

interface GetConceptPreviewInfoResponse {
  id: SDK.UUID;
  assetType?: string;
  previewUrl?: string;
  thumbnailUrl?: string;
}

const KEY = 'concept-preview-info';
type Key = typeof KEY;

type Result = GetConceptPreviewInfoResponse | null;
type QueryKey = [Key, GetConceptPreviewInfoRequest];

type QueryOptions = UseQueryOptions<Result, unknown, Result, QueryKey>;
type QueryResult = UseQueryResult<Result>;

const getConceptPreviewInfo = async (requestParams: GetConceptPreviewInfoRequest) => {
  const { projectId, conceptId, size, assetId } = requestParams;
  const queryParams = [
    projectId ? `projects=${projectId}` : null,
    size ? `size=${size}` : null,
    assetId ? `assets=${assetId}` : null,
  ];

  return provider.getJson<object, Result>(
    `${SDK.BASE_URL()}/concepts/${conceptId}/preview?${queryParams.filter(Boolean).join('&')}`
  );
};

const queryFn: QueryFunction<Result, QueryKey> = ({ queryKey: [, requestParams] }) =>
  getConceptPreviewInfo(requestParams);

// This is exported so we can use useQueries
export function getConceptPreviewInfoQueryOptions(
  requestParams: GetConceptPreviewInfoRequest,
  options?: QueryOptions
): QueryOptions {
  return {
    queryKey: [KEY, requestParams],
    queryFn,
    ...options,
  };
}

export default function useConceptPreviewInfo(
  requestParams: GetConceptPreviewInfoRequest,
  options?: QueryOptions
): QueryResult {
  return useQuery(getConceptPreviewInfoQueryOptions(requestParams, options));
}
